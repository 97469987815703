import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Translate from "../../../config/Translate";
import FileSaver from "file-saver";
import {
  DOWNLOAD_MEDIDCAL_REPORT,
  GET_DOWNLOAD_FILE_URL,
  GET_MEDICAL_BIOCHIMIE,
  GET_MEDICAL_HEMATOLOGIE,
  GET_MEDICAL_PJRECORDS,
  GET_MEDICAL_RECORDS,
  GET_MEDICAL_REPORT,
  GET_MEDICAL_VIGNETTERECORDS,
  GET_PETS_URL,
} from "../../../helper/Url";
import { AXIOS_GET } from "../../../config/axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ReactComponent as DownLoad } from "../../../assets/icons/download.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import AddImage from "../../../assets/images/addAnimal.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ViewRecord from "./ViewRecord";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  MedicalRecordModal,
  selectpetView,
  selectRecordModal,
} from "../../../features/ShowAccountSlice";
import { selectLanguage } from "../../../features/LanguageSlice";
import Loader from "../../../helper/Loader";
const MedicalRecordsContainer = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [skNumber, setskNumber] = useState([1, 2, 3, 4, 5]);
  const [requestSent, setrequestSent] = useState(true);
  const showRecordModal = useSelector(selectRecordModal);
  const [petlistvalue, setpetlistvalue] = useState("*");
  const dispatch = useDispatch();
  const petView = useSelector(selectpetView);
  const [DataLinks, setDataLinks] = useState();
  const [DataForPet, setDataForPet] = useState();
  const languageValue = useSelector(selectLanguage);
  const [typeOfRecord, settypeOfRecord] = useState("medicals");
  const [downloadSingleDoc, setdownloadSingleDoc] = useState(false);
  const [downloadSingleDocIndex, setdownloadSingleDocIndex] = useState();

  const [PetsData, setPetsData] = useState([]);
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const changeActiveCtg = (e) => {
    document.querySelectorAll(".ctg-link").forEach((ctgLink) => {
      ctgLink.classList.remove("activeCtg");
    });
    e.target.classList.add("activeCtg");
    settypeOfRecord(e.target.getAttribute("name"));
  };
  const changeActivePage = (e) => {
    document.querySelectorAll(".pg-link").forEach((pgLink) => {
      pgLink.classList.remove("active-page-link");
    });
    e.target.classList.add("active-page-link");
  };

  const getMedicalRecords = (searchQuery = "*", page = 1) => {
    setData([]);
    setrequestSent(true);
    setDataLinks([]);
    AXIOS_GET(`${GET_MEDICAL_RECORDS}/${searchQuery}?page=${page}`, token)
      .then((result) => {
        setrequestSent(false);
        setData(result.data.data.data.filter((item) => item.med_type !== "VIGNETTE"));
        setDataLinks(result.data.data.links);
      })
      .catch((e) => {
        setrequestSent(false);
      });
  };
  const getMedicalReports = (searchQuery = "*", page = 1) => {
    setData([]);
    setDataLinks([]);
    setrequestSent(true);
    setpetlistvalue("*");
    AXIOS_GET(`${GET_MEDICAL_REPORT}/${searchQuery}?page=${page}`, token)
      .then((result) => {
        setrequestSent(false);
        setData(result.data.data.data);
        setDataLinks(result.data.data.links);
      })
      .catch((e) => {});
  };
  const getMedicalBiochimie = () => {
    AXIOS_GET(GET_MEDICAL_BIOCHIMIE, token)
      .then((result) => {
        setData(result.data.data.data);
        console.log("medical records is: ", result.data.data.data);
      })
      .catch((e) => {
        console.log(`Can't get medical records`);
      });
  };
  const getMedicalHematologie = () => {
    AXIOS_GET(GET_MEDICAL_HEMATOLOGIE, token)
      .then((result) => {
        setData(result.data.data.data);
        console.log("medical records is: ", result.data.data.data);
      })
      .catch((e) => {
        console.log(`Can't get medical records`);
      });
  };
  const getMedicalVignette = (searchQuery = "*", page = 1) => {
    setData([]);
    setrequestSent(true);
    setDataLinks([]);
    setpetlistvalue("*");
    AXIOS_GET(
      `${GET_MEDICAL_VIGNETTERECORDS}/${searchQuery}?page=${page}`,
      token
    )
      .then((result) => {
        setrequestSent(false);
        setData(result.data.data.data);
        setDataLinks(result.data.data.links);
      })
      .catch((e) => {});
  };
  const getMedicalPj = (searchQuery = "*", page = 1) => {
    setData([]);
    setrequestSent(true);
    setDataLinks([]);
    setpetlistvalue("*");
    AXIOS_GET(`${GET_MEDICAL_PJRECORDS}/${searchQuery}?page=${page}`, token)
      .then((result) => {
        setrequestSent(false);
        setData(result.data.data.data);
        setDataLinks(result.data.data.links);
      })
      .catch((e) => {
        setrequestSent(false);
      });
  };
  const getAllPets = (petname = "*") => {
    setrequestSent(true);
    AXIOS_GET(GET_PETS_URL, token)
      .then((res) => {
        setrequestSent(false);
        setPetsData(res.data.data);
        petname != "*" && setpetlistvalue(petname);
      })
      .catch((e) => {});
  };
  const getRecordByPagination = (url) => {
    setData([]);
    setrequestSent(true);
    AXIOS_GET(url, token)
      .then((result) => {
        setrequestSent(false);
        setData(result.data.data.data);
        setDataLinks(result.data.data.links);
      })
      .catch((e) => {
        setrequestSent(false);
      });
  };

  const openMedicalRecord = (pet) => {
    setDataForPet(pet);
    dispatch(MedicalRecordModal());
  };

  const downloadSinglePetFile = (
    path = null,
    index = null,
    fileName = "medical_record.pdf"
  ) => {
    setdownloadSingleDocIndex(index);
    setdownloadSingleDoc(true);
    fetch(DOWNLOAD_MEDIDCAL_REPORT + path, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response?.data?.message == "404" || response.status != 200) {
          languageValue === "En"
            ? toast("No file associated with this pet")
            : toast("Aucun fichier associé à cet animal");
          setdownloadSingleDocIndex(null);
          setdownloadSingleDoc(false);
          throw new Error("Something went wrong");
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        setdownloadSingleDocIndex(null);
        setdownloadSingleDoc(false);
        FileSaver.saveAs(blob, fileName);
      });
  };
  const makeRequestonSelected = (e) => {
    setpetlistvalue(e.target.value);
    getMedicalRecords(e.target.value);
  };
  useEffect(() => {
    getMedicalRecords(petView?.payload?.id);
    getAllPets(petView?.payload?.id);
  }, []);

  return (
    <div className="pet_container_action">
      <ToastContainer />
      {/* view detail modal */}
      <ViewRecord show={showRecordModal} Data={DataForPet} />
      <div className="Intro_header">
        <div className="page_name">
          <h1>
            <Translate Word="page.pets.MyMedicalRecords"></Translate>
          </h1>
        </div>
        <div className="page_action"></div>
      </div>
      <div className="data_container">
        <div className="list_container">
          <div className="filter">
            <div className="category">
              <ul>
                <li
                  className="ctg-link activeCtg"
                  name="medicals"
                  onClick={(e) => {
                    getMedicalRecords();
                    changeActiveCtg(e);
                  }}
                >
                   <Translate Word="page.medical.all"></Translate>
                </li>
                <li
                  className="ctg-link"
                  name="report"
                  onClick={(e) => {
                    changeActiveCtg(e);
                    getMedicalReports();
                  }}
                >
                   <Translate Word="page.medical.report"></Translate>
                </li>
                {/* <li
                  className="ctg-link"
                  onClick={(e) => {
                    changeActiveCtg(e);
                    getMedicalBiochimie();
                  }}
                >
                  BIOCHIMIE
                </li> */}
                {/* <li
                  className="ctg-link"
                  onClick={(e) => {
                    changeActiveCtg(e);
                    getMedicalHematologie();
                  }}
                >
                  HÉMATOLOGIE
                </li> */}
                {/* <li
                  className="ctg-link"
                  name="vignette"
                  onClick={(e) => {
                    changeActiveCtg(e);
                    getMedicalVignette();
                  }}
                >
                  VIGNETTE
                </li> */}
                <li
                  className="ctg-link"
                  name="pj"
                  onClick={(e) => {
                    changeActiveCtg(e);
                    getMedicalPj();
                  }}
                >
                   <Translate Word="page.medical.pj"></Translate>
                </li>
              </ul>
            </div>
            <div className="category-dropdown">
              <select
                name=""
                id=""
                value={petlistvalue}
                onChange={(e) => makeRequestonSelected(e)}
              >
                {PetsData.length <= 0 ? (
                  <option value="N/A">N/A</option>
                ) : (
                  <>
                    <option value="*"><Translate Word="page.medical.pet-all"></Translate></option>
                    {PetsData.map((pet) => (
                      <>
                        <option value={pet.fm_id} key={pet.fm_id}>
                          {pet.name ? pet.name : "N/A"}
                        </option>
                      </>
                    ))}
                  </>
                )}
              </select>
            </div>
          </div>
          <table>
            <tbody>
              <tr>
                <th>
                  <Translate Word="page.pets.title"></Translate>
                </th>
                <th>
                  <Translate Word="page.pets.RecordsType"></Translate>
                </th>
                <th>
                  <Translate Word="page.pets.RecordsPetName"></Translate>
                </th>
                <th>
                  <Translate Word="page.pets.RecordsDate"></Translate>
                </th>
                <th></th>
              </tr>
              {requestSent ? (
                skNumber.map((sktr) => {
                  return (
                    <SkeletonTheme
                      baseColor="#f7f7f7"
                      highlightColor="#d3d3d3"
                      key={sktr}
                    >
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    </SkeletonTheme>
                  );
                })
              ) : Data.length <= 0 ? (
                <tr>
                  <td className="exceptional_name">
                    {languageValue === "En"
                      ? "No record"
                      : "Aucun enregistrement"}
                  </td>
                  <td></td>
                  <td className="exceptional_img_record"></td>
                  <td></td>
                  <td className="specialtd"></td>
                </tr>
              ) : (
                Data.map((pet, index) => (
                  <tr key={pet.med_id}>
                    <td className="exceptional_name">
                      {pet.medical_record_title
                        ? pet.medical_record_title
                        : "N/A"}
                    </td>
                    <td>
                      {pet.med_type === "PJ" && (
                        <span className={`color-code-5`}>{pet.med_type}</span>
                      )}
                      {pet.med_type === "VIGNETTE" && (
                        <span className={`color-code-3`}>{pet.med_type}</span>
                      )}
                      {pet.med_type === "REPORT" && (
                        <span className={`color-code-1`}>{pet.med_type}</span>
                      )}
                    </td>
                    <td className="exceptional_img_record">
                      <div>
                        <img src={pet.web_image ? pet.web_image: AddImage } alt="" />
                      </div>
                      {pet.pet_name ? pet.pet_name : "N/A"}
                    </td>
                    <td>
                      <h5><Translate Word="page.medical.exam-date"></Translate> : {pet.exam_date ? pet.exam_date : "N/A"}</h5>
                      {/* <h5>
                        Report date:{pet.report_date ? pet.report_date : "N/A"}
                      </h5> */}
                    </td>
                    <td className="specialtd">
                      <span onClick={() => openMedicalRecord(pet)}>
                        <Eye />
                      </span>
                      {downloadSingleDoc &&
                        downloadSingleDocIndex === index && <Loader />}
                      {!downloadSingleDoc &&
                        downloadSingleDocIndex !== index && (
                          <span
                            onClick={() =>
                              downloadSinglePetFile(pet.med_id, index)
                            }
                          >
                            <DownLoad />
                          </span>
                        )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {Data.length > 0 && (
            <div className="pagination">
              {DataLinks?.map((lnk, index) => (
                <>
                  {index === 0 && (
                    <div
                      className="pg-link"
                      onClick={(e) => {
                        changeActivePage(e);
                        getRecordByPagination(lnk.url);
                      }}
                      key={lnk.url}
                    >
                      <ArrowBackIosNewIcon />
                    </div>
                  )}
                  {lnk.label != "Next &raquo;" &&
                    lnk.label != "Suivant &raquo;" &&
                    index != 0 && (
                      <div
                        className={`pg-link ${
                          lnk.active && `active-page-link`
                        }`}
                        onClick={(e) => {
                          getRecordByPagination(lnk.url);
                        }}
                        key={lnk.url}
                      >
                        {lnk.label}
                      </div>
                    )}
                  {(lnk.label == "Next &raquo;" ||
                    lnk.label == "Suivant &raquo;") && (
                    <div
                      className="pg-link"
                      onClick={(e) => {
                        changeActivePage(e);
                        getRecordByPagination(lnk.url);
                      }}
                      key={lnk.url}
                    >
                      <ArrowForwardIosIcon />
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MedicalRecordsContainer;
